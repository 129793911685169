let routes = [
  {
    path: "/",
    meta: { title: "Dashboard" },
    name: "home",
    component: () => import("../../components/moderator/pages/Index")
  },
  {
    path: "/tv-dashboard",
    name: "tv_dashboard",
    component: () => import("../../components/moderator/pages/TvDashboardPage")
  },
  {
    path: "/creme-de-la-creme",
    meta: { title: "" },
    name: "creme_de_la_creme",
    component: () => import("../../components/moderator/pages/CremeDeLaCreme")
  },
  {
    path: "/agencies",
    meta: { title: "" },
    name: "agencies",
    component: () => import("../../components/moderator/pages/Agencies")
  },
  {
    path: "/application/influencers",
    meta: { title: "" },
    name: "application.influencer",
    component: () =>
      import("../../components/moderator/pages/InfluencerChannelApplication")
  },
  {
    path: "/businesses/managed-report",
    meta: { title: "" },
    name: "managed_reports",
    component: () => import("../../components/moderator/pages/ManagedReports")
  },
  {
    path: "/businesses/managed-report/create",
    meta: { title: "" },
    name: "create_managed_report",
    component: () =>
      import("../../components/moderator/pages/CreateManagedReport")
  },
  {
    path: "/application/business/:id",
    meta: { title: "" },
    name: "application.business",
    component: () =>
      import("../../components/moderator/pages/BusinessPixelApproval")
  },
  {
    path: "/influencers",
    meta: { title: "" },
    name: "influencers",
    component: () => import("../../components/moderator/pages/Influencers")
  },
  {
    path: "/influencer/:id",
    meta: { title: "" },
    name: "influencer",
    component: () => import("../../components/moderator/pages/Influencer")
  },
  {
    path: "/businesses",
    meta: { title: "" },
    name: "businesses",
    component: () => import("../../components/moderator/pages/Businesses")
  },
  {
    path: "/business/:id",
    meta: { title: "" },
    name: "business",
    component: () => import("../../components/moderator/pages/BusinessPage")
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "" },
    component: () => import("../../components/common/profile/Profile.vue")
  },
  {
    path: "/report/sales",
    name: "report.sales",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/SalesReport")
  },
  {
    path: "/report/daily",
    name: "report.daily",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/DailyReport")
  },
  {
    path: "/report/signups",
    name: "report.signups",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/SignupReport")
  },
  {
    path: "/report/campaigns",
    name: "report.campaigns",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/CampaignReport")
  },
  {
    path: "/report/businesses",
    name: "report.businesses",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/BusinessReport")
  },
  {
    path: "/report/influencers",
    name: "report.influencers",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/InfluencerReport")
  },
  {
    path: "/report/commission",
    name: "report.commission",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/CommissionReport")
  },
  {
    path: "/report/clicks",
    name: "report.clicks",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/ClicksReport")
  },
  {
    path: "/conversion-logs",
    name: "conversion_logs",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/ConversionLogsPage")
  },
  {
    path: "/campaigns",
    name: "campaigns",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/Campaigns")
  },
  {
    path: "/campaign/:id",
    name: "campaign",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/Campaign")
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/Subscriptions")
  },
  {
    path: "/subscription/:id",
    name: "subscription",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/Subscription")
  },
  {
    path: "/logout",
    name: "logout",
    meta: { title: "" },
    component: () => import("../../components/app/Logout")
  },
  {
    path: "/commission-overview",
    name: "commission_overview",
    meta: { title: "" },
    component: () => import("../../components/pages/CommissionOverviewPage")
  },
  {
    path: "/influencer-transfers",
    name: "influencer_transfers",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/InfluencerTransferPage")
  },
  {
    path: "/legacy-transfers",
    name: "legacy_transfers",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/LegacyTransferPage")
  },
  {
    path: "/monthly-overview",
    name: "monthly_overview",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/MonthlyOverviewPage")
  },
  {
    path: "/translations",
    name: "translations",
    meta: { title: "" },
    component: () => import("../../components/moderator/pages/Translations")
  },
  {
    path: "/*",
    name: "404",
    meta: { title: "Siden blev ikke fundet" },
    component: () => import("../../components/business/pages/404")
  }
];

export default routes;
