export const ClickConversionTimeSchema = `{
    seconds
    minutes
    hours
    days
}`;

export const SalesPerDayAndTimeSchema = `{
    day_number
    day_name
    time_interval
    sales
    commission
}`;
export const CategoryStatsSchema = `{
    id
    name
    display_name
    icon
    clicks
    sales
    value
    commission
    campaigns
    influencers
}`;
export const SignUpsSchema = `{
    data{
        date
        businesses
        influencers
    }
}`;
export const ManagedReportsSchema = `{
    id
    url
    date
    secret
    summary
    created_at
    updated_at
    business{
        uuid
        display_name
    }
}`;
export const BusinessInfluencerActivityStatsSchema = `{
    total
    logins
    performing
}`;

export const MonthlyFinanceOverview = `{
    year
    data {
        month
        paid_by_brands
        not_paid_by_brands
        paid_service_fee_by_brands
        not_paid_service_fee_by_brands
        paid_to_private_influencers
        paid_to_business_influencers
        not_paid_to_private_influencers
        not_paid_to_business_influencers
        legacy_paid_to_private_influencers
        legacy_paid_to_business_influencers
    }
}`;