/* eslint-disable no-unused-vars */
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import { defineAppTheme } from "@/helpers/functions/defineAppTheme.js";
import "@/styles/app.scss";
import { themes } from "@/theme.config.js";
import chmln from "@chamaeleonidae/chmln";
import "dotenv/config";
import Echo from "laravel-echo";
import posthog from "posthog-js";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "./assets/custom-style.scss";
import "./assets/fonts.css";
import i18n from "./i18n";
import posthogPlugin from "./plugins/posthog";

chmln.init(process.env.VUE_APP_CHAMELEON_API_KEY, {
  fastUrl: process.env.VUE_APP_CHAMELEON_FAST_URL,
});


const theme = defineAppTheme(themes);
Vue.use(Vuetify);
const vuetify = new Vuetify({
  icons: {
    iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    values: {
      sort: "fal fa-angle-down",
      dropdown: "fal fa-angle-down",
      next: "fal fa-angle-right",
      prev: "fal fa-angle-left",
      clear: "fal fa-times",
    },
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: theme,
    },
  },
});

Vue.use(posthogPlugin);

/*
|--------------------------------------------------------------------------
| Packages
|--------------------------------------------------------------------------
*/

/**
 * Vue Cookies
 * https://www.npmjs.com/package/vue-cookies
 */
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Store
import store from "./store/main.js";

// Router
import VueRouter from "vue-router";
Vue.use(VueRouter);

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import VueAnalytics from "vue-analytics";

// Router logic
import router from "./router/router.js";

// Fancy grid title system
import { VueMasonryPlugin } from "vue-masonry";
Vue.use(VueMasonryPlugin);
/*
|--------------------------------------------------------------------------
| Mixins
|--------------------------------------------------------------------------
*/
import Snackbar from "./helpers/mixins/snackbar.js";
Vue.mixin(Snackbar);

import CloneDeep from "./helpers/mixins/clone_deep.js";
Vue.mixin(CloneDeep);

import ScrollToTop from "./helpers/mixins/scroll_to_top.js";
Vue.mixin(ScrollToTop);

import SignIn from "./helpers/mixins/login.js";
Vue.mixin(SignIn);

import ga_virtual_pageview from "./helpers/mixins/ga_virtual_pageview.js";
Vue.mixin(ga_virtual_pageview);

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/

import VeeValidate from "vee-validate";
// import VeeValidateDa from "vee-validate/dist/locale/da";
Vue.use(VeeValidate, {
  // locale: "da",
  dictionary: {
    // da: VeeValidateDa,
  },
  fieldsBagName: "formFields", // This is to prevent "fields" variable name being blocked in third-party components
});

import ECharts from "vue-echarts";

import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/component/axis";
import "echarts/lib/component/axisPointer";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";

Vue.component("v-chart", ECharts);

import CountryFlag from "vue-country-flag";
Vue.component("country-flag", CountryFlag);

import setLocale from "./helpers/functions/setLocale.js";

import App from "./App.vue";

// import classes
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { createProvider } from "./apollo/vue-apollo";
import ErrorHandler from "./helpers/classes/ErrorHandler.js";
import GraphQL from "./helpers/classes/GraphQL.js";
import StringBuilder from "./helpers/classes/StringBuilder.js";
import { authHelper } from "./helpers/classes/auth";
import { changeFavicon } from "./helpers/functions/changeFavicon.js";
new Vue({
  router,
  vuetify,
  store: store,
  i18n,
  render: (h) => h(App),
  computed: {
    ...mapState("loading", {
      loading: (state) => state,
    }),
    ...mapState("core/auth", {
      auth: (state) => state,
    }),
    ...mapState("settings", ["settings"]),
    ...mapGetters("subscriptions", [
      "getActiveSubscription",
      "hasSubscription",
    ]),
    ...mapGetters("core/auth", [
      "isBusiness",
      "isInfluencer",
      "isModerator",
      "isAgency",
    ]),
  },

  methods: {
    ...mapActions("core/auth", ["setUser", "refreshToken", "loginToken"]),
    ...mapActions("loading", ["setSystemLoading", "getSystemSettings"]),
    ...mapActions("settings", ["setSettings"]),
    ...mapActions("subscriptions", ["fetchSubscription"]),
    ...mapActions("core/campaigns", [
      "loadCampaigns",
      "getCampaignRequestCountsForBusiness",
    ]),
    ...mapMutations("settings", ["setBuildVersion"]),
    setAppSettings() {
      let identityParams = {
        email: this.auth.user.email,
        name: this.auth.user.first_name + " " + this.auth.user.last_name,
      };

      if (this.isBusiness && this.auth.user.business) {
        identityParams.company = {
          // REQUIRED FOR COMPANY DATA, For B2B products, send company / account information here.
          uid: this.auth.user.business.uuid,
          created: this.auth.user.business.created_at,
          name: this.auth.user.business.display_name,
          plan:
            this.getActiveSubscription &&
            this.getActiveSubscription.product.name,
        };
      }

      if (this.isInfluencer && this.auth.user.influencer) {
        identityParams.influencer = {
          // REQUIRED FOR COMPANY DATA, For B2B products, send company / account information here.
          uid: this.auth.user.influencer.uuid,
          created: this.auth.user.influencer.created_at,
          name:
            this.auth.user.influencer.first_name +
            " " +
            this.auth.user.influencer.last_name,
          country: this.auth.user.influencer.country,
          ageRestrictions: this.auth.user.influencer.age_restrictions,
        };
      }

      chmln.identify(this.auth.user.uuid, identityParams);
      posthog.identify(this.auth.user.uuid, identityParams);

      if (this.isBusiness) {
        // // Theme
        // const colors = colorsConfig.business;
        // this.setAppColors(colors.primary, colors.secondary, colors.accent);
        // Router
        if (this.$authHelper.getShadowLogin()) {
          this.$router.addRoutes(require("./router/routes/shadowBusiness.js").default);
        } else {
          this.$router.addRoutes(require("./router/routes/business.js").default);
        }
        
        // icon
        changeFavicon(this.$favicons.business);
        // Google analytics
        if (
          !this.$authHelper.getShadowLogin() &&
          this.settings.app_env === "production"
        ) {
          Vue.use(VueAnalytics, {
            id: "UA-130645103-4",
            set: [{ field: "anonymizeIp", value: "true" }],
            router,
          });
        }
        this.initIntercomBusiness();
      } else if (this.isInfluencer) {
        // Theme
        // const colors = colorsConfig.influencer;
        // this.setAppColors(colors.primary, colors.secondary, colors.accent);
        // icon
        changeFavicon(this.$favicons.influencer);
        // Router
        this.$router.addRoutes(
          require("./router/routes/influencer.js").default
        );

        // Google analytics
        if (
          !this.$authHelper.getShadowLogin() &&
          this.settings.app_env === "production"
        ) {
          Vue.use(VueAnalytics, {
            id: "UA-130645103-3",
            set: [{ field: "anonymizeIp", value: "true" }],
            router,
          });
        }
      } else if (this.isModerator) {
        // Theme
        // const colors = colorsConfig.moderator;
        // this.setAppColors(colors.primary, colors.secondary, colors.accent);
        // Router
        this.$router.addRoutes(require("./router/routes/moderator.js").default);
      } else if (this.isAgency) {
        // const colors = colorsConfig.agency;
        // this.setAppColors(colors.primary, colors.secondary, colors.accent);
        // icon
        changeFavicon(this.$favicons.business);
        // Router
        this.$router.addRoutes(require("./router/routes/agency.js").default);

        // Google analytics
        if (
          !this.$authHelper.getShadowLogin() &&
          this.settings.app_env === "production"
        ) {
          Vue.use(VueAnalytics, {
            id: "UA-130645103-5",
            set: [{ field: "anonymizeIp", value: "true" }],
            router,
          });
        }
      }
      if (
        (this.$isProduction && !this.auth) ||
        (!this.$authHelper.getShadowLogin() &&
          this.auth &&
          this.auth.user &&
          !this.isModerator)
      ) {
        if (this.auth && this.auth.user && !this.isModerator) {
          let userTypeid = null;

          if (this.isAgency) {
            userTypeid = this.auth.user.agency.id;
          } else if (this.isBusiness) {
            userTypeid = this.auth.user.business.id;
          } else if (this.isInfluencer) {
            userTypeid = this.auth.user.influencer.id;
          }

          let userData = {
            // User data
            name: this.auth.user.first_name + " " + this.auth.user.last_name,
            email: this.auth.user.email,
            user_id: userTypeid,
            created_at: this.auth.user.created_at,

            // Custom attributes
            account_type: this.$authHelper.getUserType().toLowerCase(),
          };

          let referralType = this.$route.query.referral_type;
          let referralValue = this.$route.query.referral_value;

          if (referralType) {
            userData.referral_type = referralType;
          }

          if (referralValue) {
            userData.referral_value = referralValue;
          }

          this.initHubspot(userData);
        }
      }
      this.initIntercom();
      this.removePreload();
      // If not production use dev analytics
      if (!this.isModerator && this.settings.app_env !== "production") {
        Vue.use(VueAnalytics, {
          id: "UA-130645103-6",
          set: [{ field: "anonymizeIp", value: "true" }],
          router,
        });

        return;
      }
    },
    removePreload() {
      document.getElementById("preload").remove();
    },
    validateToken() {
      this.$authHelper.setApiHeaders();
      // If login token use that.
      const url_string = window.location.href;
      const url = new URL(url_string);
      const token = url.searchParams.get("token");
      const isShadow = url.searchParams.get("shadow") !== "false";
      if (token) {
        this.$authHelper.destroyTokens();
        const params = {
          token,
        };
        this.loginToken(params).then(
          (response) => {
            const userType = response.user.user_type.substring(11);
            this.$authHelper.setUserType(userType);
            this.$authHelper.setUserId(response.user.uuid);
            this.$authHelper.setTokensFromResponse({
              ...response,
              shadow: isShadow,
            });
            window.location.replace("/");
          },
          (error) => {
            // cannot validate shadow login tocken
            this.$store.dispatch("core/auth/logout");
            this.getOut();
          }
        );
      } else {
        this.$authHelper.checkToken().then(
          (response) => {
            this.authUser();
          },
          (error) => {
            // token invalid or refresh error
            this.getOut();
          }
        );
      }
    },
    authUser() {
      this.$store.dispatch("core/auth/getUserFromApi").then(
        (user) => {
          // Set user data
          this.$store.dispatch("core/auth/setAuth", user);
          // get app settings

          if (
            this.$route.path === "/influencer-signup" ||
            this.$route.path === "/login" ||
            this.$route.path === "/business-signup"
          ) {
            router.push("/");
          }
          this.getAppSettings();
        },
        (error) => {
          // cannot get user
          this.getOut();
        }
      );
    },
    setAppLocale(lang = "en") {
      setLocale(i18n, lang);
    },
    getAppSettings() {
      if (this.auth.user.user_type === "App\\Models\\Business") {
        this.fetchSubscription();
      }
      Promise.all([
        this.getSystemSettings(),
        this.$axios.get("/version.json"),
      ]).then(
        (values) => {
          const systemSettings = values[0];
          const version = values[1].version;
          this.setSystemLoading(false);
          this.setSettings(systemSettings);
          this.setBuildVersion(version);
          setInterval(() => {
            // version update
            this.$axios.get("/version.json").then((versions) => {
              this.setBuildVersion(versions.data.version);
            });
            // get system settings
            this.getSystemSettings().then((settings) => {
              this.setSettings(settings);
            });
          }, 60000);
          this.setAppSettings();
          if (!this.isModerator) {
            this.initFacebookPixel();
          }
          if (this.isBusiness) {
            this.initHotjar();
          }
        },
        (error) => {
          // failed to get system settings
          this.getOut();
          console.error(error);
        }
      );
    },
    getOut() {
      this.setSystemLoading(false);
      this.removePreload();
      this.$store.dispatch("core/auth/logout");
      this.$authHelper.destroyTokens();
      this.initFacebookPixel();
      this.initHubspot();
      // avoide navigation duplicated
      if (
        this.$route.path !== "/influencer-signup" &&
        this.$route.path !== "/business-signup" &&
        this.$route.path !== "/login"
      ) {
        console.error("App initiation error");
        router.push("/login");
      }
    },
    initGlobalVariables() {
      /**
       * We'll load the axios HTTP library which allows us to easily issue requests
       * to our Laravel back-end. This library automatically handles sending the
       * CSRF token as a header based on the value of the "XSRF" token cookie.
       */
      // global variables
      Vue.prototype.$isProduction = process.env.NODE_ENV === "production";
      Vue.prototype.$theme = theme;
      // packages
      Vue.prototype.$axios = require("axios");
      Vue.prototype.$moment = require("moment");
      Vue.prototype.$loadash = require("lodash");
      // Classes
      Vue.prototype.$gql = new GraphQL(this.$apollo);
      Vue.prototype.$errorHandler = new ErrorHandler(this);
      Vue.prototype.$stringBuilder = new StringBuilder();
      // Auth helper
      Vue.prototype.$authHelper = authHelper();
      // favicons
      Vue.prototype.$favicons = {
        influencer: "/img/favicons/influencer.png",
        influencerNotification: "/img/favicons/influencer-notification.png",
        business: "/img/favicons/business.png",
        businessNotification: "/img/favicons/business-notification.png",
      };
      // moment js locale
      // require("moment/locale/en");
      this.$moment.locale("en");
      // axios add deafalut headers
      this.$axios.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
    },
    initFacebookPixel() {
      if (this.$isProduction) {
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        );
        window.fbq("init", "207327986837684");
      }
    },
    initHotjar() {
      if (this.$isProduction) {
        (function(h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function() {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 1775754, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      }
    },
    // addWindowBaseEvents() {
    //   window.sleep = ms => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    //   };
    //   // Log all JS errors on server
    //   window.onerror = (message, url, lineNumber) => {
    //     var data = {
    //       message: message,
    //       url: window.location.href,
    //       line: lineNumber
    //     };
    //     this.$axios.post("/api/log", data);
    //     return false;
    //   };
    // },
    initEcho() {
      /**
       * Echo exposes an expressive API for subscribing to channels and listening
       * for events that are broadcast by Laravel. Echo and event broadcasting
       * allows your team to easily build robust real-time web applications.
       */
      window.Pusher = require("pusher-js");

      window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.VUE_APP_PUSHER_APP_KEY,
        authEndpoint: process.env.VUE_APP_PUSHER_APP_AUTH_HOST,
        wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
        wsPort: process.env.VUE_APP_PUSHER_PORT,
        disableStats: true,
        // encrypted: true,
        auth: {
          headers: {
            Authorization: "Bearer " + this.$authHelper.getAccessToken(),
          },
        },
      });
    },
    initHubspot(userData) {
      if (!this.$isProduction) {
        return;
      }
      let _hsq = (window._hsq = window._hsq || []);

      let imported = document.createElement("script");
      imported.src = "//js.hs-scripts.com/7733342.js";
      document.body.appendChild(imported);

      _hsq.push(["identify", userData]);
    },
    initIntercomBusiness() {
      let loadBusinessData = async () => {
        let params = {};
        let campaigns = [];
        let maxParticipants = 0;
        let totalAccepted = 0;
        let totalInvitations = 0;
        let totalApplications = 0;
        let totalAvailable = 0;

        // Load active campaign count
        await this.loadCampaigns({ active_campaigns: true, draft: false })
          .then((_campaigns) => {
            campaigns = _campaigns.response;
          })
          .catch((err) => {
            // Do nothing to avoid crash
          });

        await campaigns.forEach((value, index) => {
          maxParticipants += value.max_participants;

          this.getCampaignRequestCountsForBusiness({
            campaign_uuid: value.uuid,
          }).then((_campaignRequestsForBusinesses) => {
            totalAccepted +=
              _campaignRequestsForBusinesses.activeInfluencersCount;
            totalInvitations +=
              _campaignRequestsForBusinesses.pendingInvitationsCount;
            totalApplications +=
              _campaignRequestsForBusinesses.pendingApplicationsCount;
          });
        });

        params.mi_campaigns_active_count = campaigns.length;
        params.mi_campaign_max_participants_count = maxParticipants;
        params.mi_campaign_request_accepted_count = totalAccepted;
        params.mi_campaign_request_invited_count = totalInvitations;
        params.mi_campaign_request_application_count = totalApplications;

        this.initIntercom(params);
      };

      loadBusinessData();
    },
    initIntercom(accountParams = {}) {
      try {
        // Do not work in production, or for non business/influencer users
        if (
          !this.$isProduction ||
          !(
            this.auth &&
            this.auth.user &&
            (this.isInfluencer || this.isBusiness)
          )
        ) {
          window.Intercom = function(...params) {
            console.log("[DEV] INTERCOM", params);
          };

          window.Intercom();
          return;
        }

        let intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "oqzln94h",
        };

        if (this.auth && this.auth.user) {
          intercomSettings.user_id = this.auth.user.uuid;
          intercomSettings.name = this.auth.user.full_name;
          intercomSettings.email = this.auth.user.email;
          intercomSettings.created_at = this.$moment(
            this.auth.user.created_at
          ).unix();

          if (this.isBusiness) {
            accountParams.mi_account_type = "business";
            accountParams.mi_pixel_approved_at = this.auth.user.business.pixel_approved_at;

            if (this.hasSubscription !== null) {
              accountParams.mi_subscription_active = this.getActiveSubscription.is_active;
              accountParams.mi_subscription_cancelled = this.getActiveSubscription.is_canceled;
            } else {
              accountParams.mi_subscription_active = false;
              accountParams.mi_subscription_cancelled = false;
            }
          } else if (this.isInfluencer) {
            accountParams.mi_account_type = "influencer";
          } else {
            accountParams.mi_account_type = "other";
          }

          // Prepare for the new account system
          let company = {
            company_id: this.auth.user.accounts[0].uuid,
            ...accountParams,
          };

          if (this.isBusiness) {
            company.name = this.auth.user.business.display_name;
          }

          if (this.isInfluencer) {
            company.name =
              "Infl. " +
              this.auth.user.first_name +
              " " +
              this.auth.user.last_name;
          }

          intercomSettings.company = company;
          intercomSettings.companies = [company];
        }

        window.intercomSettings = intercomSettings;

        (function() {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === "function") {
            ic("reattach_activator");
            ic("update", w.intercomSettings);
          } else {
            var d = document;
            var i = function() {
              i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
              i.q.push(args);
            };
            w.Intercom = i;
            var l = function() {
              var s = d.createElement("script");
              s.type = "text/javascript";
              s.async = true;
              s.src = "https://widget.intercom.io/widget/oqzln94h";
              var x = d.getElementsByTagName("script")[0];
              x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === "complete") {
              l();
            } else if (w.attachEvent) {
              w.attachEvent("onload", l);
            } else {
              w.addEventListener("load", l, false);
            }
          }
        })();
      } catch (e) {
        console.error("Intercom load", e);
      }
    },
  },
  watch: {
    getActiveSubscription() {
      this.initIntercom();
    },
  },
  apolloProvider: createProvider(),
  created() {
    // variables
    this.initGlobalVariables();
    // // add base window events
    // this.addWindowBaseEvents();
    // init Echo
    this.initEcho();
    // run validate -> authorize -> setup chain
    this.validateToken();

    if (this.$route.query.lang && this.$route.query.lang.length > 0) {
      this.setAppLocale(this.$route.query.lang);
    } else {
      this.setAppLocale("da");
    }
  },
}).$mount("#app");
